import auth from "./auth.store";
import { store as creditor } from "@/views/creditor";
import snackbar from "./snackbar.store";
import blacklist from "../views/creditor/pages/Blacklistdays/store/index.module"
import notification_history from "../views/creditor/pages/notificationHistory/store/index.module"
import creditor_rule_history from "../views/rule/store/history.module"
import notification from "../views/notification/pages/store/index.module";

export default {
  auth,
  creditor,
  snackbar,
  blacklist,
  notification_history,
  creditor_rule_history,
  notification,
};