<template>
  <v-app :class="cssClass">
    <v-system-bar app dark window color="red" height="40" class="justify-center font-weight-medium"
      v-if="$store.state.enviroment.name == 'staging'">
      HOMOLOGAÇÃO
    </v-system-bar>

    <TopToolbar v-if="user && user.id" />
    <Menu v-if="user && user.id" />

    <v-main>
      <v-container v-if="(user && user.id) || $route.name == 'Error'" fluid>
        <router-view/>
      </v-container>

      <v-container v-else fill-height fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" class="text-center">
              <img
                src="@/assets/img/Logo.png"
                class="logo"
                title="Logo" />

              <v-progress-circular
                indeterminate
                color="secondary"
                class="d-flex mx-auto"
              ></v-progress-circular>
            </v-col>
        </v-row>
      </v-container>
    </v-main>
    <SnackBar/>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "./components/common/Menu";
import SnackBar from "./components/SnackBar";
import TopToolbar from "./components/common/TopToolbar";

export default {
  name: "App",
  components: {
    Menu,
    TopToolbar,
    SnackBar,
  },
  computed: {
    ...mapGetters("auth", { user: "getMe" }),

    /** Retorna o nome da classe para ser usado no <v-app> */
    cssClass() {
      if (!this.$route || !this.$route.name) {
        return '';
      }

      return 'page-' + this.$route.name.toLowerCase();
    }
  },
};
</script>

<style lang="scss">
.v-application.theme--light {
  .v-main {
    background-color: #fafafa !important;
  }
}

.notification-type {
  &.PROPOSAL_AGREED {
    background-color: #E1BEE7 !important;
  }

  &.PROPOSAL_ACTIVE_AGREEMENT,
  &.PROPOSAL_CONFIRMED,
  &.PROPOSAL_EXTENDED,
  &.PROPOSAL_EXTENSION_REJECTED,
  &.PROPOSAL_NOT_ELEGIBLE,
  &.PROPOSAL_REJECTED,
  &.PROPOSAL_WAITING {
    background-color: #F0E68C !important;
  }

  &.BOLETO_CHANGED,
  &.BOLETO_SCHEDULED,
  &.BOLETO_SENDED,
  &.LAST_PAYMENT_IDENTIFIED,
  &.PAYMENT_IDENTIFIED {
    background-color: #99e599 !important;
  }

  &.PAYMENT_REMINDER {
    background-color: #EF9A9A !important;
  }

  &.PAYMENT_EXPIRATION  {
    background-color: #FFE0B2 !important;
  }

  &.PAYMENT_EXPIRED {
    background-color: #E57373 !important;
  }
}
</style>
