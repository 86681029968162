<template>
  <v-app-bar v-if="$route.meta.showAppBar" app fixed elevate-on-scroll>
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      @click="$store.commit('toggleDrawer')">
    </v-app-bar-nav-icon>

    <!-- Título -->
    <v-toolbar-title v-if="$route.meta.title">
      {{ $route.meta.title }}

      <span v-if="$route.meta.showRule && creditor.name" class="font-weight-bold">
        - {{ creditor.name }}
      </span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- Infomação de dias de tolerância -->
    <div
      v-if="$route.meta.showRule && creditor.id"
      class="hidden-sm-and-down align-center qj-rule-tolerance"
    >
      <!-- Tolerância para o pagamento da entrada -->
      <div class="mr-4">
        <strong>TOLERÂNCIA</strong>
      </div>

      <div>
        Cash: 
        <strong v-if="creditor.down_payment_tolerance_days" class="text-lowercase">
          {{ creditor.down_payment_tolerance_days }} dias
          {{ creditor.down_payment_tolerance_days_type | daysType }}
        </strong>
        <strong v-else>-</strong>
      </div>

      <div class="px-2">-</div>

      <!-- Tolerância para o pagamento das parcelas -->
      <div>
        Colchão:
        <strong v-if="creditor.installment_tolerance_days" class="text-lowercase">
          {{ creditor.installment_tolerance_days }} dias
          {{ creditor.installment_tolerance_days_type | daysType }}
        </strong>
        <strong v-else>-</strong>
      </div>
    </div>

    <v-spacer></v-spacer>

    <!-- Botão para testar a régua -->
    <v-btn
      v-if="$route.meta.showRule && ruleId && $route.name != 'RuleSimulation'"
      rounded
      depressed
      dark
      color="deep-purple lighten-1"
      :to="{ name: 'RuleSimulation', params: { id: ruleId } }"
    >
      Testar Régua
    </v-btn>

    <v-spacer v-if="!$route.meta.showRule"></v-spacer>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          target="_blank"
          href="https://docs.google.com/document/d/e/2PACX-1vTw_Y7TkOAWyMJmwCZ_PKj0YrwkhO_lhsuz8fSIpIBbz8uE0XGY3LW7VMgEEmd3DH6VXdBlPVTLAquO/pub"
          v-bind="attrs"
          v-on="on"
          color="red"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </template>

      <span>Ajuda</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("creditor", {
      creditor: "getCreditor",
    }),

    /** Retorna o id da régua do credor. */
    ruleId() {
      return this.$route.params.ruleId || this.creditor.rule_id;
    }
  },
};
</script>

<style scoped lang="scss">
.qj-rule-tolerance {
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  margin: 0 16px;
  padding: 8px 16px;
}
</style>
