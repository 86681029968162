import Vue from 'vue';
import Vuex from 'vuex';
import enviroment from '@/assets/js/enviroment';
import modules from "./modules";

import { version } from "@/../package.json";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,

  state: {
    app: {
      /** Indica se o v-navigation-drawer deve ser exibido. */
      drawer: true,

      /** A versão do app. */
      version: version,
    },

    /** Ambiente da aplicação. */
    enviroment: enviroment,
  },
  mutations: {
    toggleDrawer(state) {
      state.app.drawer = !state.app.drawer;
    },
  },
});