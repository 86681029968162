<template>
  <div>
    <!-- Menu -->
    <v-navigation-drawer
      app
      v-model="$store.state.app.drawer"
    >
      <!-- Cabeçalho do menu -->
      <template v-slot:prepend>
        <!-- Parte do app-bar em cima do menu -->
        <v-toolbar
          id="toolbar-logo"
          flat
        >
          <v-list-item class="pa-0 mx-n1">
            <!-- Imagem da logo -->
            <router-link to="/" title="Ir para o início" class="d-inline-block">
              <v-img src="@/assets/img/Logo.png" alt="Logo" transition="none" />
            </router-link>

            <v-spacer></v-spacer>

            <!-- Menu com o avatar do usuário -->
            <AvatarMenu />
          </v-list-item>
        </v-toolbar>

        <!-- Loading -->
        <v-progress-linear
          absolute
          indeterminate
          color="deep-purple accent-2"
          v-if="loading"
        ></v-progress-linear>
        <!-- Input de pesquisa -->
        <v-text-field
          dense
          outlined
          hide-details
          class="pa-2"
          type="search"
          placeholder="Pesquisar credor"
          color="deep-purple accent-2"
          prepend-inner-icon="mdi-magnify"
          :disabled="loading"
          v-model="search"
        ></v-text-field>
      </template>
      <!-- Corpo do menu -->
      <template v-slot:default>
        <div style="padding: 0 10px 10px 0">
          <!-- Erro ao carregar a lista -->
          <div v-if="loadingError" class="pl-2">
            <v-alert
              color="red"
              text
            >
              <v-row align="center">
                <v-col class="grow">
                  Não foi possível carregar a lista de credores.
                </v-col>
                <v-col class="shrink">
                  <v-btn color="primary" @click="loadAllCreditors()">
                    Tentar novamente
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </div>

          <!-- Lista de credores -->
          <v-list shaped dense class="pa-0">
            <v-list-item-group
              color="deep-purple accent-2"
              v-model="selectedCreditor"
            >
              <v-list-item
                class="edit-creditor pr-0"
                v-for="creditor in creditorsFiltered"
                :key="creditor.id"
                :value="creditor.id"
                :to="{ name: 'Rule', params: { id: creditor.rule_id } }"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="creditor.name"
                    style="font-size: 14px"
                  ></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="my-0">
                  <v-btn icon :to="{ name: 'Creditor', params: { id: creditor.id } }">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <!-- Botão novo credor -->
          <v-btn
            block
            text
            tile
            style="
              justify-content: initial;
              font-size: 14px;
              letter-spacing: normal;
              border-radius: 0 25px 25px 0;
              text-transform: none;
              height: 40px;
            "
            class="pr-2"
            :disabled="loading"
            :to="{ name: 'Creditor', params: { id: 'new' } }"
          >
            Novo
            <v-spacer></v-spacer>
            <v-icon color="grey">mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:append>
        <div class="pt-1 text-center text--secondary text-caption">
          <strong>
            <a
              :href="backofficeUrl + '/notification_rule'"
              class="text--secondary text-caption"
              target="_blank">Backoffice</a>
          </strong>
        </div>

        <div class="pb-1 text-center text-caption text--secondary">
          v{{ $store.state.app.version }}
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AvatarMenu from "../AvatarMenu";
import enviroment from "@/assets/js/enviroment";

export default {
  components: {
    AvatarMenu,
  },
  data: () => ({
    backofficeUrl: enviroment.backofficeUrl,
    errorIcon: false,
    selectedCreditor: -1,
    name: "",
    search: "",
  }),
  created() {
    this.loadAllCreditors();
  },
  computed: {
    ...mapGetters("creditor", {
      creditors: "getAll",
      creditor: "getCreditor",
      loading: "isLoading",
      loadingError: "isLoadingError",
    }),
    creditorsFiltered() {
      return this.creditors.filter((creditor) => {
        return creditor.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  methods: {
    ...mapActions("creditor", {
      loadAllCreditors: "ActionsLoadAllCreditors",
    }),
  },
};
</script>

<style scoped lang="scss">
.v-application.theme--light {
  #toolbar-logo {
    background-color: var(--v-primary-base);
  }
}

.edit-creditor {
  .v-btn {
    opacity: 0;
    transition: 0.2s;
  }
}
.edit-creditor:hover {
  .v-btn {
    opacity: 1;
    transition: 0.2s;
  }
}
</style>
