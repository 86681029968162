export default [
  {
    path: '/creditor/:id',
    name: 'Creditor',
    component: () => import(/* webpackChunkName: "home" */ './pages/Creditor'),
    meta: {
      title: 'Credor',
      showAppBar: false,
    },
  },
  {
    path: '/blacklist-days',
    name: 'BlackListDays',
    component: () => import(/* webpackChunkName: "home" */ './pages/Blacklistdays/Blacklistdays'),
    meta: {
      title: 'Feriados',
      showAppBar: true,
    },
  },
  {
    path: '/notification/:notification_id/history',
    name: 'NotificationHistory',
    component: () => import('./pages/notificationHistory/notificationHistory'),
    meta: {
      title: 'Histórico de Alterações',
      showAppBar: true,
    }
  }
]
