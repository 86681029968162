import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: localStorage.getItem('zeusDarkMode') === 'true',
    themes: {
      light: {
        primary: '#7c4dff',
        secondary: colors.yellow,
      },
    },
  },
});
