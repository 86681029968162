import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  /**
   * Retorna um lista de credores
   * 
   * @returns 
   */
   all() {
    let params = {
      order: 'name',
      limit: 1000,
    };

    return http.api.get(VERSION + "/creditor", { params });
  },
  /**
   * Retorna um credor especifico
   * 
   * @param {*} id 
   * @returns 
   */
   get(id) {
    return http.api.get(VERSION + "/creditor/" + id);
  },
  /**
   * Cria um credor
   * 
   * @param {*} payload 
   * @returns 
   */
   create(payload) {
    return http.api.post(VERSION + "/creditor", payload);
  },
  /**
   * Deleta um credor
   * 
   * @returns 
   */
   delete(id) {
    return http.api.delete(VERSION + "/creditor/" + id);
  },
  /**
   * Atualiza um credor
   * 
   * @returns 
   */
   update(id, payload) {
    return http.api.patch(VERSION + "/creditor/" + id, payload);
  },
  /**
   * Publica uma régua em produção.
   */
  publishRule(id, payload) {
    return http.api.patch(VERSION + "/creditor/" + id + "/publish_rule", payload);
  },
}