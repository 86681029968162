import serviceNotification from "@/services/notification"

const state = {
  list: {
    objects: new Array(),
  },
  selected: null,
  loadingError: false,
  loading: false,
};
const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  setSelected(state, payload) {
    state.selected = payload;
  },
  setLoadingError(state, payload) {
    state.loadingError = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  updateNotification(state, payload) {
    state.loading = payload;
  },
};
const actions = {
  async getAll({ commit }, payload) {
    const { data } = await serviceNotification.getMessageVariable(payload);
    commit("setList", data);
  },
  async update({ commit }, notification) {
    let response = serviceNotification.update(notification.id, notification);

    return response
      .then((response) => {
        commit('updateNotification', response.data);
      })
  },
};
const getters = {
  list: (state) => state.list,
  selected: (state) => state.selected,
  loadingError: (state) => state.loadingError,
  loading: (state) => state.loading,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
