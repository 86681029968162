import Vue from "vue"

/** Representa o tipo dos dias: úteis ou corridos. */
Vue.filter("daysType", type => {
  let types = {
    BUSINESS: 'Úteis',
    CALENDAR_DAYS: 'Corridos',
  };

  return types[type] || type;
})

Vue.filter("firstWord", str => {
  if (!str) return "";
  return str.split(" ")[0];
});

Vue.filter("paymentMethod", type => {
  let types = {
    BOLETO: 'Boleto',
    DEBIT_CARD: 'Débito Automático',
    FIRST_BOLETO_OTHERS_CREDIT: 'Primeiro boleto, outros crédito',
    FIRST_BOLETO_OTHERS_DEBIT: 'Primeiro boleto, outros débito automático',
    FIRST_PIX_OTHERS_BOLETO: 'Primeiro Pix, outros boleto',
    FIRST_PIX_OTHERS_DEBIT: 'Primeiro Pix, outros débito automático',
    PARCELE_JA: 'Parcele Já',
    PIX: 'Pix',
    CREDIT: 'Crédito'
  };

  return types[type] || type;
})

/** Representa o tipo do pagamento. */
Vue.filter("paymentType", type => {
  let types = {
    IN_CASH: 'Cash',
    INSTALLMENT: 'Colchão',
  };

  return types[type] || type;
})

Vue.filter("sendNextBusinessDay", type => {
  let types = {
    true: 'Sim',
    false: 'Não',
  };

  return types[type] || type;
})

Vue.filter("whenFilter", type => {
  let types = {
    BEFORE: 'Antes',
    AFTER: 'Depois',
  };

  return types[type] || type;
})

Vue.filter("notificationType", type => {
  let types = {
    PROPOSAL_EXTENDED: 'Acordo prorrogado.',
    PROPOSAL_ACTIVE_AGREEMENT: 'Promessa existente.',
    PROPOSAL_AGREED: 'Proposta aceita.',
    PROPOSAL_WAITING: 'Proposta aguardando.',
    PROPOSAL_CONFIRMED: 'Proposta confirmada.',
    PROPOSAL_NOT_ELEGIBLE: 'Proposta não aprovada: não elegível.',
    PROPOSAL_REJECTED: 'Proposta rejeitada.',
    PROPOSAL_EXTENSION_REJECTED: 'Prorrogação de acordo rejeitado.',

    BOLETO_SCHEDULED: 'Boleto agendado.',
    BOLETO_CHANGED: 'Boleto atualizado.',
    BOLETO_SENDED: 'Boleto enviado.',
    PAYMENT_IDENTIFIED: 'Pagamento identificado.',
    PAYMENT_EXPIRED: 'Pagamento expirado.',
    LAST_PAYMENT_IDENTIFIED: 'Último pagamento identificado (acordo quitado).',

    PAYMENT_REMINDER: 'Vencimento.',
    PAYMENT_EXPIRATION: 'Expiração',
  };

  return types[type] || type;
})

Vue.filter("active", type => {
  let types = {
    true: 'Ativo',
    false: 'Inativo',
  };

  return types[type] || type;
})
