import http from "@/plugins/axios";

const VERSION = "/v1";

export default {


  get(params) {
    return http.api.get(VERSION + "/creditor_rule_history", { params });
  },

}