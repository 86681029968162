export default [
  {
    path: '/creditor/rule/:id',
    name: 'Rule',
    component: () => import(/* webpackChunkName: "home" */ './pages/Rule'),
    props: true,
    meta: {
      title: 'Régua',
      showAppBar: true,
      showRule: true,
    },
  },
  {
    path: '/rule/:id/simulation',
    name: 'RuleSimulation',
    component: () => import(/* webpackChunkName: "home" */ './pages/Simulation'),
    props: true,
    meta: {
      title: 'Simulação',
      showAppBar: true,
      showRule: true,
    },
  },
  {
    path: '/creditor/:id/rule-history',
    name: 'RuleHistory',
    component: () => import(/* webpackChunkName: "home" */ './pages/History'),
    meta: {
      title: 'Histórico da Régua',
      showAppBar: true,
      showRule: true,
    },
  },
]
