export default [
  {
    path: '/404',
    alias: '*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "home" */ './pages/PageNotFound'),
    meta: {
      title: 'Página não encontrada',
      showAppBar: false,
      showBtns: false,
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "home" */ './pages/Error'),
    meta: {
      title: 'Erro',
      requiresAuth: false,
    },
  },
]
