export default [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ './pages/Home/Home'),
    meta: {
      title: 'Tolerância para Pagamento',
      showAppBar: false,
    },
  },
]
