import store from "@/store";

export default async (to, from, next) => {
  document.title = to.meta.title + " - Zeus"

  if(to.meta.requiresAuth !== undefined && !to.meta.requiresAuth) next();
  else store.dispatch('auth/checkAuth').then((isAuthenticated) => {
    if (isAuthenticated) next();
    else next({name: 'authenticate'});
  }).catch(() => {
    next('/error');
  });
};