/* eslint-disable */
import http from "@/plugins/axios";
import oauth from '../services/oauth'
import enviroment from "@/assets/js/enviroment";
import * as Sentry from "@sentry/browser";

const state = {
  me: null,
  token: null,
}

const mutations = {
  setToken(state, token) {
    console.debug('Salvando o token de autenticação.');

    state.token = token;
    localStorage.setItem('zeusAuthToken', token);
    http.setToken(token);
  },
  setMe(state, me) {
    console.debug('Salvando o usuário autenticado:', me.email);

    state.me = me;
    localStorage.setItem('zeusUser', JSON.stringify(me));

    Sentry.setUser({
      id: me.id,
      email: me.email,
      username: me.name,
    });
  },
}

const actions = {
  auth({ commit, dispatch }, token) {
    return new Promise(async (resolve) => {
      if (token) {
        commit('setToken', token);
      } else {
        console.error('Autenticação sem token');
        goToQuiteJaAuth();
        return;
      }

      console.debug('Consultando o usuário autenticado.');

      await oauth.me().then(res => {
        console.debug('Usuário autenticado:', res.data.email);
        commit('setMe', res.data);
        resolve();
      }).catch((err) => {
        console.error('Não foi possível buscar o usuário autenticado.', err);
        dispatch('logout');
        goToQuiteJaAuth();
      });
    });
  },
  logout() {
    console.debug('Deslogando.');

    state.me = null
    state.token = null

    localStorage.removeItem('zeusAuthToken')
    localStorage.removeItem('zeusUser')
  },
  async checkAuth({ commit, dispatch }) {
    if (state.me && state.me.id && state.token) return true;

    const token = localStorage.getItem('zeusAuthToken');

    if (token) {
      commit('setToken', token);
      http.setToken(token);
    } else {
      console.debug('Token de autenticação não encontrado.');
      goToQuiteJaAuth();
      return;
    }

    console.debug('Consultando o usuário autenticado.');

    await oauth.me().then(res => {
      commit('setMe', res.data);
    }).catch((error) => {
      console.error('Não foi possível buscar o usuário autenticado.', error);

      let response = error.response || {};

      if (response.status == 401) {
        dispatch('logout');
        goToQuiteJaAuth();
        return;
      }

      throw error;
    });

    return true;
  }
}

const getters = {
  getMe({ me }) { return me }
}

function goToQuiteJaAuth() {
  window.location = enviroment.apiUrl + 'oauth/login';
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};