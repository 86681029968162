import Vue from 'vue';
import store from "@/store";
import routes from './routes';
import VueRouter from 'vue-router';
import beforeEach from './beforeEach';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    ...routes,
    {
      path: '/authenticate',
      name: 'authenticate',
      beforeEnter: (to, from, next) => {
        store.dispatch('auth/auth', to.query.token).then(() => next('/'))
      },
      meta: {
        requiresAuth: false
      }
    },
  ],
  mode: 'history' });
router.beforeEach(beforeEach);

router.onError((error) => {
  console.error("Não foi possível carregar a rota.", error);
});

window.$router = router;

export default router;