import serviceNotificationHistory from "@/services/notification_history";

const state = {
    list: {
        objects: new Array(),
    },
    selected: null,
    loadingError: false,
    loading: false
}
const mutations = {
    setList(state, payload){
        state.list = payload
    },
    setSelected(state, payload){
        state.selected = payload
    },
    setLoadingError(state, payload){
        state.loadingError = payload
    },
    setLoading(state, payload){
        state.loading = payload
    }
}
const actions = {
    async getAll({ commit }, payload){
        commit('setLoading', true);
        commit('setList', {objects: new Array()});
        try {
            const { data } = await serviceNotificationHistory.get(payload)
            commit('setList', data);
            commit('setLoadingError', false);
            commit('setLoading', false);
        } catch (error) {
            commit('setLoadingError', true);
        }
    },
}
const getters = {
  list: (state) => state.list,
  selected: (state) => state.selected,
  loadingError: (state) => state.loadingError,
  loading: (state) => state.loading
}
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};