import Vue from "vue";
import * as types from "./mutation-types";

/**
 * As mutations que fazem as modificações nas variaves do state.
 */
export default {
  [types.SET_ALL](state, payload) {
    state.all = payload;
  },
  [types.SET_CREDITOR](state, payload) {
    state.creditor = payload;
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [types.SET_LOADING_ERROR](state, payload) {
    state.loadingError = payload;
  },
  [types.SET_SAVING](state, payload) {
    state.saving = payload;
  },
  [types.UPDATE_CREDITOR](state, savedCreditor) {
    // Atualiza o credor atualmente selecionado
    if (state.creditor && state.creditor.id == savedCreditor.id) {
      Vue.util.extend(state.creditor, savedCreditor);
    }

    // Verifica se o credor existe na lista de credores
    let creditor = state.all.find(creditor => creditor.id == savedCreditor.id);

    if (creditor) {
      // O credor foi atualizado
      Vue.util.extend(creditor, savedCreditor);
    } else {
      // O credor foi criado
      state.all.push(savedCreditor);
    }

    // Ordena a lista de credores por ordem alfabética
    state.all.sort((creditorA, creditorB) => {
      return creditorA.name.localeCompare(creditorB.name);
    })
  },
}
