<template>
  <v-menu v-if="me" bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on">
        <v-avatar v-if="me.profile_photo_url">
          <img :src="me.profile_photo_url" :alt="me.name" />
        </v-avatar>
        <v-avatar color="primary" v-else>
          <v-icon color="white" style="font-size: 26px">mdi-account-circle</v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center pb-0">
        <div class="mx-auto text-center">
          <v-avatar v-if="me.profile_photo_url">
            <img :src="me.profile_photo_url" :alt="me.nome" />
          </v-avatar>
          <v-avatar class="qj-bar-color" v-else>
            <v-icon color="white" style="font-size: 26px">mdi-account-circle</v-icon>
          </v-avatar>
          <h3>{{ me.name }}</h3>
          <p class="text-caption mt-1">
            {{ me.email }}
          </p>

          <v-list class="pa-0">
            <v-divider></v-divider>

            <v-list-item :to="{name: 'MessageVariable' }">
              <v-list-item-title>Variáveis para as mensagens</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item href="https://metabase.quiteja.com.br/question/6896" target="_blank">
              <v-list-item-title>Todas as mensagens <v-icon>mdi-open-in-new</v-icon></v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item href="https://metabase.quiteja.com.br/question/6897" target="_blank">
              <v-list-item-title>Histórico de alterações <v-icon>mdi-open-in-new</v-icon></v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item :href="authUrl + '/my-apps'" target="_blank">
              <v-list-item-title>Meus apps</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="logoutAppBar">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import environment from "@/assets/js/enviroment";

export default {
  data: () => ({
    authUrl: environment.authUrl,
  }),
  computed: {
    ...mapGetters("auth", { me: "getMe" }),
  },
  methods: {
    ...mapActions("auth", [
      "logout",
    ]),

    logoutAppBar() {
      this.logout().then(() => {
        window.location.href = this.authUrl;
      });
    },

    toggleDarkMode() {
      localStorage.setItem('zeusDarkMode', this.$vuetify.theme.dark);
    },
  },
};
</script>

<style scoped lang="scss"></style>
