import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  /**
   * Lista os feriados.
   */
  get(params) {
    return http.api.get(VERSION + "/blacklistdays", { params });
  },

  /**
  * Salva ou edita os feriados.
  */
  save(payload){
      if(payload.id){
        return http.api.patch(VERSION + "/blacklistdays/" + payload.id, payload);
      }
      return http.api.post(VERSION + "/blacklistdays", payload);
  },

  /**
  * Exclui os feriados.
  */
  delete(payload){
    return http.api.delete(VERSION + "/blacklistdays/" + payload.id);
  },

}
