export default {
  /** Todos os credores. */
  all: [],

  /** Credor atualmente selecionado. */
  creditor: {},

  /** Indica se está carregando a lista de credores. */
  loading: false,

  /** Indica se houve erro ao carregar a lista de credores. */
  loadingError: false,

  /** Indica se está salvando o credor. */
  saving: false,
}
