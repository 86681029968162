import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import { name, version } from '@/../package.json'
import './assets/css/global.css';
import './filters'

// Plugins
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import "./registerServiceWorker"

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  console.debug("Configurando o Sentry");

  // Integração com o Sentry
  Sentry.init({
    dsn: 'https://66a3fb58c2094b958202c1435ce25928@o92838.ingest.sentry.io/6150405',
    environment: (window.location.host.indexOf('.quiteja.com.br') !== -1) ? 'production' : 'staging',
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
    release: name + '@' + version,
    sendDefaultPii: true,
  });
}

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

const moment = require("moment");
require("moment/locale/pt-br");
 
Vue.use(require("vue-moment"), {
    moment,
});

console.debug("Aplicação criada");
