import { routes as error } from '../../views/error';
import { routes as home } from '../../views/home';
import { routes as notification } from '../../views/notification';
import { routes as rule } from '../../views/rule';
import { routes as creditor } from '../../views/creditor';

export default [
  ...error,
  ...home,
  ...notification,
  ...rule,
  ...creditor,

  // Rota temporária para acessar a lista de assets
  {
    path: '/asset',
    component: () => import('@/components/AssetList'),
  },
];
