import * as types from "./mutation-types";

import serviceCreditor from "@/services/creditor";

/**
 * Busca todos os credores.
 * 
 * @param {Function} commit Função para armazenar no state.
 */
export const ActionsLoadAllCreditors = ({ commit }) => {
  commit(types.SET_LOADING, true);
  commit(types.SET_LOADING_ERROR, false);

  console.debug("Carregando a lista de credores.");

  serviceCreditor.all()
    .then((resp) => {
      commit(types.SET_ALL, resp.data.objects);
      console.debug("Credores carregados:", resp.data.objects.length);
    })
    .catch((error) => {
      console.error("Não foi possível listar os credores.", error);
      commit(types.SET_LOADING_ERROR, true);
    })
    .finally(() => {
      commit(types.SET_LOADING, false);
    });
};

/**
 * Buscar o credor e armazena no state.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {Number} id o id do credor.
 */
export const ActionsLoadCreditor = ({ commit }, id) => {
  console.log("Buscando o credor", id);

  return serviceCreditor.get(id)
    .then((response) => {
      console.log("Credor carregado.");
      commit(types.SET_CREDITOR, response.data);

      return response.data;
    })
    .catch((error) => {
      console.log("Não foi possível buscar o credor.", error);
    })
};

/**
 * Salva o credor.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {Array<Object>} creditor o credor.
 */
export const ActionsCreateCreditor = ({ commit }, creditor) => {
  console.log("Criando o credor");

  commit(types.SET_SAVING, true);

  return serviceCreditor.create(creditor)
    .then((response) => {
      console.log("Credor salvo.");
      commit(types.UPDATE_CREDITOR, response.data);

      return response;
    })
    .finally(() => {
      commit(types.SET_SAVING, false);
    });
};

/**
 * Salva o credor.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {Array<Object>} creditor o credor.
 */
export const ActionsSaveCreditor = ({ commit }, creditor) => {
  console.log("Salvando o credor");

  commit(types.SET_SAVING, true);

  let response = creditor.id
    ? serviceCreditor.update(creditor.id, creditor)
    : serviceCreditor.create(creditor);

  return response
    .then((response) => {
      console.log("Credor salvo.");
      commit(types.UPDATE_CREDITOR, response.data);
    })
    .finally(() => {
      commit(types.SET_SAVING, false);
    });
};
