import serviceBlacklistdays from "@/services/blacklistday";

const state = {
    list: {
        objects: new Array(),
    },
    selected: null,
}
const mutations = {
    setList(state, payload){
        state.list = payload
    },
    setSelected(state, payload){
        state.selected = payload
    },
    pushList(state, payload){
        let verifyIfExist = state.list.objects.find((blacklist) => blacklist.id == payload.id)
        if(verifyIfExist){
           let index = state.list.objects.indexOf(verifyIfExist)
           state.list.objects[index] = payload
           state.list = JSON.parse(JSON.stringify(state.list))
        } else{
            state.list.objects.push(payload)
        }
    }
}
const actions = {
    async getAll({ commit }, payload){
        const { data } = await serviceBlacklistdays.get(payload)
        commit('setList', data)
    },
    async save({ commit }, payload){
        const response = await serviceBlacklistdays.save(payload)
        commit('pushList', response.data)
        commit('setSelected', response)
    },
    async delete({ commit }, payload){
        const response = await serviceBlacklistdays.delete(payload)
        commit('setSelected', response)
    }
}
const getters = {
  list: (state) => state.list,
  selected: (state) => state.selected
}
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};