const host = window.location.hostname;
const configs = {
  localhost: {
    apiUrl: "http://localhost:5400/",
    assetsUrl: "http://localhost:5003",
    assetsAppUrl: "http://localhost:8540",
    authUrl: "http://localhost:9292",
    backofficeUrl: "http://localhost:8283/#!",
    converterUrl: "http://localhost:5920",
    name: "development",
  },
  "zeus.quiteja.staging.gorillascode.com": {
    apiUrl: "https://api.zeus.quiteja.staging.gorillascode.com/",
    assetsUrl: "https://assets.quiteja.staging.gorillascode.com",
    assetsAppUrl: "https://assets.quiteja.staging.gorillascode.com/app",
    authUrl: "https://auth.staging.gorillascode.com",
    backofficeUrl: "https://backoffice.quiteja.staging.gorillascode.com",
    converterUrl: "https://converter.staging.gorillascode.com",
    name: "staging",
  },
  "zeus.quiteja.com.br": {
    apiUrl: "https://api.zeus.quiteja.com.br/",
    assetsUrl: "https://assets.quiteja.com.br",
    assetsAppUrl: "https://assets.quiteja.com.br/app",
    authUrl: "https://auth.quiteja.com.br",
    backofficeUrl: "https://backoffice.quiteja.com.br",
    converterUrl: "https://converter.gorillascode.com",
    name: "production",
  },
};

export default {
  /** Todas as configurações de todos os ambientes. */
  configs: configs,

  /** URL da API do Zeus. */
  apiUrl: configs[host].apiUrl,

  /** URL do renderizador do Assets. */
  assetsUrl: configs[host].assetsUrl,

  /** URL da interface de administração do Assets. */
  assetsAppUrl: configs[host].assetsAppUrl,

  /** URL do QuiteJá Auth. */
  authUrl: configs[host].authUrl,

  /** URL do Backoffice. */
  backofficeUrl: configs[host].backofficeUrl,

  /** URL do Converter. */
  converterUrl: configs[host].converterUrl,

  /** Nome do ambiente onde a aplicação está rodando. */
  name: configs[host].name,
  host,
};
