export default [
  {
    path: '/notification/:id?',
    name: 'Notification',
    component: () => import(/* webpackChunkName: "home" */ './pages/Notification'),
    props: route => ({
      id: route.params.id,
      ruleId: route.query.rule,
    }),
    meta: {
      title: 'Notificação',
      showAppBar: true,
      showRule: true,
    },
  },
  {
    path: '/variables',
    name: 'MessageVariable',
    component: () => import(/* webpackChunkName: "home" */ './pages/MessageVariable'),
    meta: {
      title: 'Variáveis para mensagens',
      showAppBar: false,
      showRule: false,
    },
  },
]
