import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  /**
   * Retorna uma notificação.
   * 
   * @param {*} id 
   * @returns 
   */
  get(id) {
    return http.api.get(VERSION + "/notification/" + id);
  },

  /**
   * Lista as notificações.
   */
  search(params) {
    return http.api.get(VERSION + "/notification", { params });
  },

  /**
   * Cria uma notificação
   * 
   * @param {*} payload 
   * @returns 
   */
  create(payload) {
    return http.api.post(VERSION + "/notification", payload);
  },

  /**
   * Atualiza uma notificação.
   */
  update(id, payload) {
    return http.api.patch(VERSION + "/notification/" + id, payload);
  },

  getMessageVariable(params){
    return http.api.get(VERSION + '/message_variable', {params})
  }
}
